import { CircularProgress, Stack } from '@mui/material';
import { CampaignBrief as CampaginBriefComp } from '@media-ant/ui';
import React, { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import apiService from '../../apiService';
import CustomButton from '../../../components/SharedHashes/Shared/CustomButton';
import { submitBrief, updateCurrentState } from '../redux/slice';
import { SnackBarActions } from '../../Shared/SnackBar/slice';
import WarninigPopUp from '../../../components/SharedHashes/Shared/WarninigPopUp';
import SaveDraftModal from '../../../components/SharedHashes/Shared/SaveDraftModal';
import useHash, {
  loadingState
} from '../../../components/SharedHashes/Shared/customHooks/useHash';
import HashHeaders from '../../../components/SharedHashes/HashHeaders';
import { TMediaSpecificDetail } from './interface';

type TSharedCampaignBrief = {
  nextMenuPath: string;
  stages: {
    [key: string]: string;
  };
  toolName: string;
};

export const validateAndFormatData = (data: unknown) => {
  let isValidData: boolean = true;
  let formattedValidData: number = 0;
  if (data === '') {
    isValidData = false;
  } else if (typeof data === 'number') {
    isValidData = true;
    formattedValidData = data;
  } else if (typeof data === 'string') {
    let parsedBudget = parseInt(data);
    if (isNaN(parsedBudget)) {
      isValidData = false;
    } else {
      isValidData = true;
      formattedValidData = parsedBudget;
    }
  } else {
    isValidData = false;
  }
  return { isValidData, formattedValidData };
};

export const errorValidationMediaSpecificDetail = (
  toolName: string,
  mediaSpecificDetail: TMediaSpecificDetail
) => {
  let error = '';

  let { isValidData: isValidBudget, formattedValidData: formattedValidBudget } =
    validateAndFormatData(mediaSpecificDetail?.budget);

  let {
    isValidData: isValidDuration,
    formattedValidData: formattedValidDuration
  } = validateAndFormatData(mediaSpecificDetail?.duration?.value);

  if (mediaSpecificDetail === undefined)
    error = `In Media Preferences, "${toolName}" vertical must be checked to proceed further. Please ensure the "${toolName}" vertical is selected.`;
  else if (!isValidBudget) error = `Enter valid ${toolName} vertical budget`;
  else if (!formattedValidBudget || formattedValidBudget < 10_000)
    error = `${toolName} vertical minimum budget should be 10,000`;
  else if (formattedValidBudget > 200_000_000)
    error = `${toolName} vertical Maximum budget should be 200,000,000`;
  else if (!isValidDuration)
    error = `Enter valid ${toolName} vertical duration`;
  else if (!formattedValidDuration || formattedValidDuration < 1)
    error = `${toolName} vertical minimum duration should be 1`;
  else if (formattedValidDuration > 365)
    error = `${toolName} vertical Maximum duration should be 365`;
  else if (
    !mediaSpecificDetail?.age?.length ||
    mediaSpecificDetail.age.length === 0
  )
    error = `Please fill target group age in ${toolName} vertical`;
  else if (
    !mediaSpecificDetail?.gender?.length ||
    mediaSpecificDetail.gender.length === 0
  )
    error = `Please fill target group gender in ${toolName} vertical`;
  else if (!mediaSpecificDetail?.geos || mediaSpecificDetail.geos.length === 0)
    error = `Please fill geography in ${toolName} vertical`;
  else if (
    !mediaSpecificDetail?.income?.length ||
    mediaSpecificDetail.income.length === 0
  )
    error = `Please fill target group income in ${toolName} vertical`;

  return error;
};

const SharedCampaignBrief = ({
  nextMenuPath,
  stages,
  toolName
}: TSharedCampaignBrief) => {
  const formRef: any = useRef();
  const [
    isLoading,
    setIsLoading,
    openDraftModal,
    setOpenDraftModal,
    openCautionDialog,
    setOpenCautionDialog
  ] = useHash();
  const campaign = useAppSelector(
    (state: any) => state.planRequests?.plan?._campaign
  );
  const currentState: number = useAppSelector(
    (state: any) => state?.planRequests?.plan?.currentState
  );
  const [openCautionDraft, setOpenCautionDraft] = useState(false);
  const [cautionDraftLoading, setCautionDraftLoading] = useState(false);

  const dispatch = useAppDispatch(),
    navigate = useNavigate(),
    { planId } = useParams();

  const validateCampaignBriefData = () => {
    let data = formRef.current.getValues();

    let {
      isValidData: isValidBudget,
      formattedValidData: formattedValidBudget
    } = validateAndFormatData(data?.campaignDetails?.budget);

    let {
      isValidData: isValidDuration,
      formattedValidData: formattedValidDuration
    } = validateAndFormatData(data?.campaignDetails?.duration?.value);

    let error = '';
    if (!data.brand.email) error = 'Email is required.';
    else if (data.brand.email.includes('@themediaant.com'))
      error = 'Internal User email is not allowed';
    else if (!isValidBudget) error = 'Enter valid campaign budget';
    else if (!formattedValidBudget || formattedValidBudget < 10_000)
      error = 'Minimum campaign budget should be 10,000';
    else if (formattedValidBudget > 200_000_000)
      error = 'Maximum campaign budget should be 200,000,000';
    else if (!isValidDuration) error = `Enter valid campaign duration`;
    else if (!formattedValidDuration || formattedValidDuration < 1)
      error = `Campaign minimum duration should be 1`;
    else if (formattedValidDuration > 365)
      error = `Campaign maximum duration should be 365`;
    else if (
      !data?.targetGroup?.age?.length ||
      data.targetGroup.age.length === 0
    )
      error = 'Please fill target group age';
    else if (
      !data?.targetGroup?.gender?.length ||
      data.targetGroup.gender.length === 0
    )
      error = 'Please fill target group gender';
    else if (
      !data?.targetGroup?.geos?.length ||
      data.targetGroup.geos.length === 0
    )
      error = 'Please fill geography';
    else if (
      !data?.targetGroup?.income?.length ||
      data.targetGroup.income.length === 0
    )
      error = 'Please fill target group income';

    return error;
  };

  const validateMediaSpecificDetailsData = (toolName: string) => {
    let data = formRef.current?.getValues(),
      mediaSpecificDetail = data?.mediaSpecificDetails?.find(
        (mediaSpecificDetail: any) => mediaSpecificDetail?.tool === toolName
      ),
      error = errorValidationMediaSpecificDetail(toolName, mediaSpecificDetail);

    return error;
  };

  const moveToNextStage = (campaignId: string) => {
    dispatch(
      submitBrief({
        campaignId: campaignId as string,
        brief: formRef.current.submitForm()
      })
    )
      .then((res: any) => {
        if (!res.error) {
          dispatch(updateCurrentState({ state: 'campaign-brief' })).then(
            (res: any) => {
              if (!res.error && nextMenuPath) {
                navigate(`/plans/${planId}${nextMenuPath}`);
              }
            }
          );
        }
      })
      .finally(() => {
        setIsLoading(loadingState.default);
        setOpenCautionDialog(false);
      });
  };

  const handleSubmit = (campaignId: string, toolName: string) => {
    const error = validateCampaignBriefData(),
      verticalLevelError = validateMediaSpecificDetailsData(toolName);

    if (error || verticalLevelError)
      dispatch(
        SnackBarActions.openSnack({
          open: true,
          message: error || verticalLevelError,
          type: 'error'
        })
      );
    else {
      if (!Boolean(currentState)) {
        setIsLoading(loadingState.saveAsDraftLoading);
        dispatch(
          submitBrief({
            campaignId: campaignId as string,
            brief: formRef.current.submitForm()
          })
        )
          .then((res: any) => {
            if (!res?.error) {
              setOpenDraftModal(true);
            }
          })
          .finally(() => {
            setIsLoading(loadingState.default);
          });
      } else {
        setOpenCautionDraft(true);
      }
    }
  };

  const updateCampaignBriefAndResetCurrentState = (campaignId: string) => {
    setCautionDraftLoading(true);
    dispatch(
      submitBrief({
        campaignId: campaignId as string,
        brief: formRef.current.submitForm()
      })
    );
    // .then((res: any) => {
    //   if (!res?.error) {
    //     dispatch(updateCurrentState({ state: 'campaign-brief-draft' })).then(
    //       (res: any) => {
    //         if (!res?.error) {
    //           setOpenDraftModal(true);
    //         }
    //       }
    //     );
    //   }
    // })
    // .finally(() => {
    //   setOpenCautionDraft(false);
    //   setCautionDraftLoading(false);
    // });
  };

  const handleSaveAndNext = (campaignId: string) => {
    const error = validateCampaignBriefData(),
      verticalLevelError = validateMediaSpecificDetailsData(toolName);

    if (error || verticalLevelError)
      dispatch(
        SnackBarActions.openSnack({
          open: true,
          message: error || verticalLevelError,
          type: 'error'
        })
      );
    else if ((currentState ?? 0) > parseInt(stages[''])) {
      setOpenCautionDialog(true);
    } else {
      setIsLoading(loadingState.saveAndNextLoading);
      moveToNextStage(campaignId);
    }
  };

  return (
    <Stack sx={{ height: '100%' }}>
      <HashHeaders title="Campaign Brief" />
      <Stack sx={{ padding: '24px', flexGrow: 1 }}>
        {campaign !== null && campaign && (
          <CampaginBriefComp
            enableEdit={true}
            ref={formRef}
            brief={campaign?.brief}
            apiService={apiService}
            isUploadRequired={false}
          />
        )}
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        sx={{
          padding: '12px 24px',
          gap: '24px',
          boxShadow: '0px -2px 12px 0px rgba(9, 30, 66, 0.15)',
          marginTop: '60px',
          position: 'sticky',
          backgroundColor: '#FAFBFC',
          zIndex: 1000
        }}
      >
        <CustomButton
          variant="outlined"
          disabled={loadingState.saveAsDraftLoading === isLoading}
          onClick={() => handleSubmit(campaign?._id, toolName)}
          startIcon={
            loadingState.saveAsDraftLoading === isLoading ? (
              <CircularProgress size={24} sx={{ color: '#4E3BC9' }} />
            ) : null
          }
        >
          Save as draft
        </CustomButton>
        <CustomButton
          variant="contained"
          onClick={() => handleSaveAndNext(campaign?._id)}
          disabled={loadingState.saveAndNextLoading === isLoading}
          startIcon={
            loadingState.saveAndNextLoading === isLoading ? (
              <CircularProgress size={24} />
            ) : null
          }
        >
          Save & Next
        </CustomButton>
      </Stack>
      <WarninigPopUp
        open={openCautionDialog}
        handleClose={() => setOpenCautionDialog(false)}
        isLoading={loadingState.cautionLoading === isLoading}
        handleContinue={() => {
          setIsLoading(loadingState.cautionLoading);
          moveToNextStage(campaign?._id);
        }}
      />
      <WarninigPopUp
        open={openCautionDraft}
        handleClose={() => setOpenCautionDraft(false)}
        isLoading={cautionDraftLoading}
        isDraft={true}
        handleContinue={() => {
          updateCampaignBriefAndResetCurrentState(campaign?._id);
        }}
      />
      <SaveDraftModal
        open={openDraftModal}
        handleClose={setOpenDraftModal}
        brandName={'campaign brief'}
      />
    </Stack>
  );
};

export default SharedCampaignBrief;
